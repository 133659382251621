@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

:root {
  --secondary-color: #26ddf9;
  --other-color: #010304;
  --white-color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  position: relative;
}

a,
li {
  list-style: none;
  text-decoration: none;
}

.tradingview-widget-copyright {
  display: none;
}

img {
  width: 100%;
  height: auto;
}

input,
select {
  font-size: 16px;
}

.telegram_wrapper {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 99999;
}

.telegram_wrapper img {
  height: 40px;
  width: 40px;
}
