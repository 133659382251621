.mobile_header,
.radius_sect,
.balance_sect,
.welcome_sect,
.balance_sect_amount,
.signal_sect {
  display: none;
}

@media (max-width: 600px) {
  .mobile_header {
    display: flex;
    background-color: rgba(38, 40, 49, 0.8);
    height: 65px;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
  }

  .support_sect {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .flag_wrapper img {
    width: 100px;
  }

  .balance_sect {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgb(167, 177, 189);
    justify-content: center;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 10px;
  }

  .balance_sect p {
    font-size: 14px;
  }

  .balance_sect_amount {
    text-align: center;
    display: block;
  }

  .balance_sect_amount h3 {
    font-size: 25px;
  }

  .welcome_sect {
    padding: 20px;
    padding-bottom: 0px;
    display: block;
  }

  .welcome_sect h3 {
    font-size: 20px;
    text-transform: capitalize;
  }

  .welcome_sect p {
    color: rgb(167, 177, 189);
    font-size: 12px;
    margin-top: 10px;
  }

  .radius_sect {
    border-radius: 10px;
    background-color: rgba(38, 40, 49);
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 94%;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .radius_sect > div,
  .bottom_nav .radius_sect a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .radius_sect p {
    font-size: 13px;
  }

  .radius_sect img {
    height: 40px;
    width: 40px;
  }

  .signal_sect {
    display: block;
  }
}
